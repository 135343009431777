import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import { RichText, Button, Modal, Anchor } from '@ordaos/nucleus'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'

import SEO from '../../components/SEO'
import CMS from '../../components/CMS/CMS'
import Link from '../../components/Link'
import Section from '../../components/Section'
import Layout from '../../layout/BasicPage'

import './team.scss'

const TeamPage = ({ data, location }) => {
	const { page, seo } = data.team
	const { teams } = page.frontmatter

	const [careers, setCareers] = useState([])
	const [modal, setModal] = useState(null)

	useEffect(() => {
		async function getJobs() {
			try {
				const resp = await fetch(
					`https://apply.workable.com/api/v1/widget/accounts/437552?details=true`,
					{
						method: 'GET',
						headers: {
							'Content-Type': 'application/json',
						},
					}
				)

				const response = await resp.json()

				setCareers(response.jobs)
			} catch (e) {
				console.log(e)
			}
		}

		getJobs()
	}, [])

	return (
		<Layout width='wide'>
			<SEO {...seo.page.metaData} href={location.href} />
			<Section
				title='Team'
				subtitle='Ordaōs is a human-enabled, machine-driven drug design company. Through partnership with industry and academia, the dedicated Ordaōs team leverages proprietary generative perception systems and reinforcement learning to create bespoke mini-proteins that help pharma and biotechs around the world deliver safer and more effective life-saving treatments in a fraction of the time of traditional discovery methods.'
				className='team'
			>
				<Layout.Inset>
					<RichText>
						{teams.map((team, i) => (
							<div className='team'>
								<h2>Meet the Team</h2>
								<div className='team-members'>
									{team.members.map((member) => (
										<TeamMember
											member={member}
											setModal={setModal}
										/>
									))}
								</div>
							</div>
						))}
					</RichText>
				</Layout.Inset>
				<Modal open={modal} onClose={() => setModal(null)}>
					{modal && (
						<div className='team-member-modal'>
							<div className='team-member-modal-two-up'>
								<CMS.Image {...modal.photo} />
								<div className='team-member-modal-two-up-content'>
									<Anchor
										className='team-member-modal-two-up-content-name'
										target='_blank'
										href={modal.linkedin}
									>
										{modal.name}
									</Anchor>
									<div className='team-member-modal-two-up-content-credentials'>
										{modal.credentials}
									</div>
									<h3 className='team-member-modal-two-up-content-title'>
										{modal.title}
									</h3>
									<CMS.Body cms={{ body: modal.bio }} />
								</div>
							</div>
						</div>
					)}
				</Modal>
			</Section>
			<Section id='careers'>
				<div className='careers-header'>
					<CMS.Description className='large' {...data.careers} />
				</div>
				<hr />
				<CMS.Body {...data.careers} />
			</Section>
			<Section title='Available Positions' className='career-section'>
				{!careers.length && (
					<p>There are currently no positions available</p>
				)}
				{careers.map((career) => (
					<Career career={career} />
				))}
			</Section>
		</Layout>
	)
}

export default TeamPage

const TeamMember = ({ member, setModal }) => {
	return (
		<div className='team-member'>
			{member.bio ? (
				<Anchor
					className={'team-member-leadership'}
					onClick={() => setModal(member)}
				>
					<div className='team-member--image'>
						<CMS.Image {...member.photo} />
					</div>
					<div className='team-member--info'>
						<p className='team-member--name'>{member.name}</p>
						<p className='team-member--title ord-u-type-preset-11'>
							{member.title}
						</p>
					</div>
				</Anchor>
			) : (
				<>
					<div className='team-member--image'>
						<CMS.Image {...member.photo} />
					</div>
					<div className='team-member--info'>
						<p className='team-member--name'>{member.name}</p>
						<p className='team-member--title ord-u-type-preset-11'>
							{member.title}
						</p>
					</div>
				</>
			)}
		</div>
	)
}

const Career = ({ career }) => {
	const [expanded, setExpanded] = useState(false)

	return (
		<div className='career' key={career.id}>
			<div className='career-header'>
				<h3 className='career-header--title'>{career.title}</h3>
				<div className='career-details'>
					<span className='career-details--category'>
						{`Category: `}
						<Link to='/#' className='career-details--category-link'>
							{career.department}
						</Link>
					</span>
					<span className='career-details--address'>
						<FontAwesomeIcon icon={faMapMarkerAlt} />
						{`${career.city}, ${career.state}, ${career.country} | ${career.employment_type}`}
					</span>
				</div>
				<button onClick={() => setExpanded(!expanded)}>
					{`See More ${expanded ? '-' : '+'}`}
				</button>
				{expanded && (
					<div className='career-body'>
						<div className='career-body--content'>
							<h4 className='career-body--title'>
								{career.full_title}
							</h4>
							<div className='career-body--description'>
								<CMS.Body cms={{ body: career.description }} />
								<div className='career-body--type'>
									<strong>{`Job Type: ${career.employment_type}`}</strong>
								</div>
								<p className='career-body--note'>
									Note: This job description in no way states
									or implies that these are the only duties to
									be performed by the employee(s) incumbent in
									this position. Employees will be required to
									follow any other job-related instructions
									and to perform any other job-related duties
									requested by any person authorized to give
									instructions or assignments. All duties and
									responsibilities are essential functions and
									requirements and are subject to possible
									modification to reasonably accommodate
									individuals with disabilities. To perform
									this job successfully, the incumbents will
									possess the skills, aptitudes, and abilities
									to perform each duty proficiently. Some
									requirements may exclude individuals who
									pose a direct threat or significant risk to
									the health or safety of themselves or
									others. The requirements listed in this
									document are the minimum levels of
									knowledge, skills, or abilities. This
									document does not create an employment
									contract, implied or otherwise, other than
									an “at will” relationship.
								</p>
								<a
									size='lg'
									href={career.application_url}
									target='_blank'
								>
									Apply Now
								</a>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	)
}

export const query = graphql`
	query Team {
		team: file(
			sourceInstanceName: { eq: "pages" }
			relativePath: { eq: "team.md" }
		) {
			...SEOFragment
			...CMSFragment
			page: childMarkdownRemark {
				frontmatter {
					pageLinks {
						title
						description
						linkText
						link
					}
					title
					description
					teams {
						title
						members {
							name
							title
							credentials
							photo {
								...CMSImageFragment
							}
							bio
							linkedin
						}
					}
				}
			}
		}
		careers: file(
			sourceInstanceName: { eq: "pages" }
			relativePath: { eq: "careers.md" }
		) {
			...SEOFragment
			...CMSFragment
		}
	}
`
